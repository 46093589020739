<template>

<div>
     <!-- Breadcrumb start -->
      <ol class="breadcrumb">
          <li class="breadcrumb-item">Clients</li>
      </ol>
      <!-- Breadcrumb end -->

      <!-- App actions start -->
      <b-button
        to="clients/new"
        class="btn btn-success float-left">
        <b-icon icon="plus-circle" />
        Add Client
      </b-button>

      <b-button variant="info" @click="synchronizeHubspot()">
        <b-icon icon="arrow-left-right" /> Synchronize with Hubspot
      </b-button>


      <b-modal ref="sync-modal" id="sync-modal" title="Synchronize logs">
        <p class="text-success" v-for="log in syncLogs" :key="log">{{ log }}</p>
        <p class="text-danger" v-for="error in syncErrors" :key="error">{{ error }}</p>
      </b-modal>

  <BMSTable
    :items="items"
    :fields="fields"
    :sort-by="sortBy"
    :urlBuilder="buildUrl"
  >
  </BMSTable>

</div>
</template>

<script>

import { getAllClients, synchronizeClients } from '@/api/clients.api'
import { getAllProjects } from '@/api/projects.api'
import BMSTable from '@/components/BMSTable';
import crudMixin from '@/api/crud'
import Vue from 'vue'

export default {
  mixins: [crudMixin],
  data() {
    return {
      fields: [{
        key: 'company',
        label: 'Company',
        sortable: true,
      }, {
        key: 'contact',
        label: 'Contact',
        sortable: true,
      }, {
        key: 'email',
        label: 'Email',
        sortable: true,
      }, {
        key: 'address.country',
        label: 'Country',
        sortable: true,
      }, {
        key: 'accounts_payable.currency',
        label: 'Currency',
        sortable: true,
      },{
        key: 'total_projects',
        label: 'Projects',
        sortable: true,
      },{
        key: 'id',
        label: 'Source',
        sortable: true,
        formatter: value => {
          return value in this.hubspotClients ? 'HUBSPOT' : 'BMS'
        }
      }],
      items: [],
      filter: null,
      sortBy: 'company',
      syncLogs: [],
      syncErrors: [],
      hubspotClients: []
    }
  },
  mounted() {
    this.getClients();
  },
  methods: {
    getClients() {
        this.call(
            getAllClients(),
            (res) => {
                this.items.splice(0, this.items.length)
                this.hubspotClients.splice(0, this.hubspotClients.length)
                res.data.clients.forEach(client => {
                    this.items.push(client)
                })
                res.data.hubspot.forEach(hs => {
                    this.hubspotClients.push(hs.client_id)
                })
                this.getProjects()
            }
        )
    },
    getProjects(){
        this.call(
            getAllProjects(),
            (res)=>{
                let project_counts = {}
                res.data.projects.forEach(project => {
                    project_counts[project.client.id] = (project_counts[project.client.id] || 0) + 1
                })
                this.items.forEach(item => {
                    Vue.set( item, 'total_projects', project_counts[item.id] || 0 )
                })
            }
        )
    },
    buildUrl(client){
      return { name: 'Client', params: { id: client.id }}
    },
    synchronizeHubspot() {
        this.call(
            synchronizeClients(),
            (res) => {
                this.syncLogs.splice(0, this.syncLogs.length)
                this.syncErrors.splice(0, this.syncErrors.length)
                res.data.log.forEach(log => {
                    this.syncLogs.push(log)
                })
                Object.values(res.data.errors).forEach(error_logs => {
                    error_logs.forEach(error => {
                        this.syncErrors.push(error)
                    })
                })
                this.$refs['sync-modal'].show()
            }
        )
    }
  },
  components: {
      BMSTable
  }
}

</script>
